const acronyms = [
  {
    acronym: '🇧🇷 pt',
    value: 'pt',
  },
  {
    acronym: '🇺🇸 en',
    value: 'en',
  },
]

export default { acronyms }
